import React, { useState } from "react";
import "./Navbar.css";

const Navbar = ({ topOffset }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <nav className="navbar" style={{ top: `${topOffset}px` }}>
      <div className="navbar-logo">
        <a href="/">

          <img src="https://hemi.xyz/wp-content/uploads/2024/04/hemi.svg" className="custom-logo" alt="Hemi" decoding="async" style={{width: '93px', height: '32px'}}/>
        </a>
      </div>

      <ul className={`navbar-menu ${isMenuVisible ? 'visible' : ''}`}>
        <li><a href="/">Resources</a></li>
        <li><a href="/">Ecosystems</a></li>
        <li><a href="/">About</a></li>
        <li><a href="/">Community</a></li>
      </ul>

      <div className="navbar-right">
        <a className="hemi-button hemi-button--primary" target="_blank" href="/app">
        Staking</a>
        <button className="menu-toggle-btn" onClick={toggleMenu}>
          &#9776; {/* Hamburger menu icon */}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
